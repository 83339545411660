import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import TeaRed from '../../assets/svg/kc-tea-red.svg';
import KCButtonBase from '../Button';
import KCModal from "../KCModal";

export interface LoginModalProps {
    open: boolean,
    onClose: Function,
    returnTo: string,
}
const LoginModal: React.FC<LoginModalProps> = ({ open, onClose, returnTo }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    return (
        <KCModal
            open={open}
            onClose={() => onClose()}
        >
            <Stack gap={isMobile ? 2 : 4} justifyContent="center">
                <Box
                    component="img"
                    sx={{
                        height: `${isMobile ? 220 : 260}px`,
                        width: `${isMobile ? 200 : 235}px`,
                        margin: 'auto'
                    }}
                    alt="Login Modal Img"
                    src={TeaRed}
                    className="login-modal-img"
                />
                <Typography variant={isMobile ? "h4" : "h3"} textAlign="center">Log in or write an anonymous review to hear the tea.</Typography>
                <Typography variant="body1" textAlign="center">Kitchen Confidentials is <b>always free</b> for food service workers, and we will never share your personal information.</Typography>
                <Stack gap={isMobile ? 1 : 2}>
                    <KCButtonBase
                        onClick={() => loginWithRedirect({
                            appState: {
                                returnTo
                            },
                            authorizationParams: {
                                screen_hint: "signup"
                            }
                        })}
                    >Log in</KCButtonBase>
                    <KCButtonBase
                        onClick={() => {
                            ReactGA.event({
                                category: "User Interaction",
                                action: "Click",
                                label: "Write Review"
                            })
                            onClose();
                            navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/write-review`)
                        }}
                    >Write a Review</KCButtonBase>
                </Stack>
            </Stack>
        </KCModal>
    );
}
export default LoginModal;