import { Box, CircularProgress, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { LocalReview } from '../../utils/helpers';
import { useNavigate, useParams } from "react-router-dom";
import { CreatePublicReviewApiArg, useCreatePublicReviewMutation } from "../../store/kcApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ReactGA from "react-ga4";

export const ResponsiveContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Box>
        {children}
      </Box>
    );
  }

  return (
    <Box
      sx={{ backgroundColor: "#FBF8F5" }}
    >
      {children}
    </Box>
  );
};

const NewReviewLandingPage: React.FC = () => {

  const { establishmentId } = useParams<{ establishmentId: string }>();

  const [createNewReview] = useCreatePublicReviewMutation();

  const { isAuthenticated } = useAuth0();
  const currentUser = useSelector((state: RootState) => state.auth.user_id);

  let navigate = useNavigate();

  useEffect(() => {
    // check local storage for existing reviews
    const reviewsJsonString = localStorage.getItem('kc_reviews');
    const existingReviews = reviewsJsonString ? JSON.parse(reviewsJsonString) : [];

    // user has an existing local review for this establishment
    if (existingReviews.length > 0) {
      const previousReviews = existingReviews.filter((review: LocalReview) => {
        return review.estId === establishmentId;
      });
      if (previousReviews.length > 1) {
        const submitted = previousReviews.filter((review: LocalReview) => {
          return review.status === "submitted";
        })
        if (submitted.length > 0) {
          navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${submitted[0].id}/submitted`)
          return
        }
      }
      if (previousReviews.length > 0) {
        navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${previousReviews[0].id}?existing=true`)
        return
      }
    }

    // create new review
    // user does not have an existing local review for this establishment and needs a new one
    const newReview: CreatePublicReviewApiArg = {
      createUpdateReview: {
        establishment_id: establishmentId,
        status: "draft",
        user_id: isAuthenticated ? currentUser : "",
      }
    };

    createNewReview(newReview)
      .unwrap()
      .then((payload) => {
        // Step 1: Retrieve the existing reviews array from Local Storage
        const reviewsJsonString = localStorage.getItem('kc_reviews');
        const existingReviews = reviewsJsonString ? JSON.parse(reviewsJsonString) : [];

        // Step 2: Add a new review object to the array
        const newReview: LocalReview = { id: payload.id || "", estId: establishmentId || "", status: "draft" };
        existingReviews.push(newReview);

        // Step 3: Update the Local Storage with the updated array
        const updatedReviewsJsonString = JSON.stringify(existingReviews);
        localStorage.setItem('kc_reviews', updatedReviewsJsonString);

        ReactGA.event({
          category: "New Review Form",
          action: "Create New Review",
          label: payload.id,
        });

        navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${payload.id}`)
      })
      .catch((error) => {
        // TODO: error handling
        console.error('Failed to create new review', error);
        if (error.status === 409) {
          ReactGA.event({
            category: "New Review Form",
            action: "Duplicate Create New Review",
            label: error.status,
          });
          navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}/new-review/${error.data}?existing=true`)
        } else {
          ReactGA.event({
            category: "New Review Form",
            action: "Failed Create New Review",
            label: error.status,
          });
          navigate(`${process.env.REACT_APP_OVERRIDE_PUBLIC_URL}/establishments/${establishmentId}`)
        }
      });


  }, [establishmentId, createNewReview, currentUser, isAuthenticated, navigate])


  return (
    <ResponsiveContainer>
      <Container sx={{ paddingTop: "70px", paddingBottom: "70px" }}>
        <Box sx={{ display: "flex", justifyContent: "center", height: "60vh" }}>
          <Stack
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" variant="indeterminate" />
            <Typography variant='body1' color='text.secondary' align='center' sx={{ padding: '60px', fontWeight: 'bold' }}>Warming up the grill and flipping the burgers! Hang tight, your table is almost ready...</Typography>
          </Stack>
        </Box>
      </Container>
    </ResponsiveContainer>
  )
}

export default NewReviewLandingPage;